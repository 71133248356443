<template>
  <div
    :style="{ 'background-color': blok?.backgroundColor || 'bg-white' }"
    :class="[{ 'relative z-[1]': !blok.backgroundColor }, 'color-canvas pt-14 pb-12']"
  >
    <img
      v-if="blok?.backgroundImage?.filename"
      :src="blok.backgroundImage.filename"
      class="absolute z-[-1] w-full h-full top-0 left-0 object-cover bg-cover bg-no-repeat"
    />
    <SectionWithMaxWidth>
      <div :class="[blok.features ? 'flex flex-col xl:flex-row px-[24px] sm:px-0' : 'flex flex-col']">
        <div class="flex flex-col px-[24px] medium:px-[24px] mobile-large:px-0 lg:px-0">
          <div
            :class="[
              blok.features ? 'md:w-full xl:w-[430px] sm:w-[260px] mobile-large:w-auto' : 'w-full',
              'text-heading-2 text-wrap',
            ]"
          >
            {{ blok.heading }}
          </div>
          <RichText v-if="blok.description" class="mt-4 mb-6" :text="blok.description" />
          <div
            v-if="blok.features"
            class="mt-6 inline-grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4"
          >
            <div
              v-for="feature of blok.features"
              :key="feature"
              data-test-id="home-page-developer-events-points"
              class="flex gap-2 items-center"
            >
              <VIcon name="check-circle-line" class="color-success-300 h-[22px] w-[22px] shrink-0" :size="-5" />
              <div data-test-id="home-page-developer-events-feature" class="text-base xl:text-xs">
                {{ feature }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow">
          <div
            data-test-id="developer-event-cards"
            :class="[
              !blok.features ? 'mt-0' : 'mt-14 xl:mt-0',
              'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 place-items-center md:place-items-stretch mb-6 xl:mb-0',
            ]"
          >
            <EventCard v-for="event in latestEvents" :key="event.id" :event-details="event" />
          </div>
          <div class="flex flex-col md:flex-row mt-4">
            <div class="flex md:hidden mt-4 mb-9 justify-center sm:justify-start">
              <NuxtLink href="/community">
                <VButton
                  class="vvd-theme-alternate"
                  size="condensed"
                  label="View All Events"
                  icon="arrow-right-line"
                  icon-trailing
                />
              </NuxtLink>
            </div>
            <div
              v-if="blok.socialLinks"
              class="grid grid-cols-3 sm:grid-cols-6 gap-[38px] mt-2 text-left md:text-center"
            >
              <EventSocialLinks v-for="socialLink in blok.socialLinks" :key="socialLink._uid" :link="socialLink" />
            </div>
            <div class="flex-grow" />
            <div class="hidden md:flex mt-2">
              <NuxtLink href="/community">
                <VButton
                  class="vvd-theme-alternate"
                  size="condensed"
                  label="View All Events"
                  icon="arrow-right-line"
                  icon-trailing
                />
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </SectionWithMaxWidth>
  </div>
</template>

<script setup lang="ts">
import { type PropType, defineProps } from 'vue';
import { VButton, VIcon } from '@vonage/vivid-vue';
import { getLatestEventsDetails } from '@/api';
import type { EventSection } from '@/types/storyblok';
import RichText from '@/components/rich-text/RichText.vue';
import EventCard from '@/components/home/vonage/events/EventCard.vue';
import EventSocialLinks from '@/components/home/vonage/events/EventSocialLinks.vue';
import SectionWithMaxWidth from '@/components/utils/SectionWithMaxWidth.vue';

const props = defineProps({
  blok: { type: Object as PropType<EventSection>, required: true },
});

const latestEvents: API.CommunityEvent[] = await getLatestEventsDetails(props?.blok?.eventCount ?? '3');
</script>
